import React, { PropsWithChildren } from "react";

import { px } from "@style/scaling";
import { paneBoxShadow } from "@style/shadow";
import styled from "@style/theme";

import { ButtonProps } from "./button";

export interface SquareButtonProps extends PropsWithChildren<ButtonProps> {
  /** The width and height of the button. */
  size?: number;
}

/**
 * A square button component, with the themed border radius.
 *
 * Buttons may be put within a `<ButtonGroup>`
 * to get a "pill-like" group of buttons
 */
export function SquareButton(props: SquareButtonProps) {
  return <ButtonBase {...props} size={props.size} />;
}

export const ButtonBase = styled.button<SquareButtonProps>`
  width: ${p => px(p.size || 42)};
  height: ${p => px(p.size || 42)};
  outline: none !important;
  user-select: none;
  border-radius: ${p => px(p.theme.borderRadius)};
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background-color: ${({ theme }) => theme.backgroundColor};
  transition: all 200ms ease;
  box-shadow: ${paneBoxShadow()};

  &:active {
    background-color: ${({ theme }) => theme.altBackgroundColor};
    color: ${({ theme }) => theme.detailColor};
  }
`;
