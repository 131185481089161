import React, { CSSProperties } from "react";

import { px } from "@style/scaling";
import styled from "@style/theme";

import { VehicleIcon, getVehicleSVG } from "./vehicle-icons";
import { Huge, SubHeading } from "../typography";

export interface TripHeaderProps {
  /**
   * The name of the trip.
   * This is usually the line number.
   */
  name?: string | null;
  /**
   * The name of the place where the trip ends.
   * This is usually displayed on the headsign of the vehicle.
   */
  towards?: string | JSX.Element | null;
  /**
   * Additional information abut the trip.
   * Can be used for e.g delay information.
   */
  subTitle?: string | JSX.Element | null;
  /** Which icon to render next to the sub title. */
  vehicleIcon?: VehicleIcon;
  /** Overrides the detail color of the "towards arrow", to this color */
  lineColor?: string;
}

function renderHeading(
  name?: string | null,
  towards?: string | JSX.Element | null,
  lineColor?: string
) {
  if (!name && !towards) {
    return <Heading />;
  } else if (name && !towards) {
    return (
      <Heading>
        <Huge>{name}</Huge>
      </Heading>
    );
  } else if (!name && towards) {
    return (
      <Heading>
        <Huge>{towards}</Huge>
      </Heading>
    );
  } else {
    const iconStyle: CSSProperties | undefined = lineColor
      ? { borderLeftColor: lineColor }
      : undefined;

    return (
      <Heading>
        <Huge>{name}</Huge>
        <TowardsIcon style={iconStyle} />
        <Huge>{towards}</Huge>
      </Heading>
    );
  }
}

export function TripHeader({
  name = null,
  towards = null,
  subTitle = null,
  vehicleIcon,
  lineColor
}: TripHeaderProps) {
  return (
    <Container>
      {renderHeading(name, towards, lineColor)}
      <SubHeading>
        {vehicleIcon ? getVehicleSVG(vehicleIcon) : null}
        {subTitle}
      </SubHeading>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${px(30)};
`;

const TowardsIcon = styled.figure`
  width: 0;
  height: 0;
  margin: ${() => px(2, 8, 0, 8)};
  border-top: ${() => px(5)} solid transparent;
  border-bottom: ${() => px(5)} solid transparent;
  border-left: ${() => px(8)} solid ${(p) => p.theme.detailColor};
`;
