import React, { CSSProperties } from "react";

import { ACCELERATE_EASING } from "@style/easing";
import styled, { UITheme } from "@style/theme";

import { StopPosition } from "./stops";
import { ThemeProps } from "styled-components";

export interface TripStopDepartureIndicatorProps {
  /**
   * The width of the line, passing through the dot.
   * Even numbers work best.
   */
  lineWidth?: number;
  /**
   * The width of the dot (and also the width of the indicator itself).
   * Even numbers work best.
   */
  dotWidth?: number;
  /**
   * When disabled, the indicator uses the `contentColor`,
   * instead of the `detailColor`, and the `dimOpacity` is applied to it.
   */
  disabled?: boolean;
  /**
   * If the position is set to "first",
   * the top part of the line is hidden.
   *
   * If the position is set to "last",
   * the bottom part of the line is hidden.
   */
  position?: StopPosition;
  /** Overrides the detail color of the line, to this color */
  lineColor?: string;
}

type Props = TripStopDepartureIndicatorProps & ThemeProps<UITheme>;

export function TripStopIndicator({
  lineWidth = 4,
  dotWidth = 10,
  position = "middle",
  disabled = false,
  lineColor,
  theme
}: Props) {
  const centerX = dotWidth / 2;
  const lineHeight = position !== "middle" ? "50%" : "100%";
  const lineY = position === "first" ? "50%" : "0";
  const lineX = centerX - lineWidth / 2;

  const style: CSSProperties | undefined =
    lineColor && !disabled
      ? {
          fill: lineColor
        }
      : undefined;

  return (
    <SVGBase style={style} isPast={disabled} width={dotWidth * theme.scale} height="100%">
      <rect
        x={lineX * theme.scale}
        y={lineY}
        height={lineHeight}
        width={lineWidth * theme.scale}
      />
      <circle cx={centerX * theme.scale} cy="50%" r={(dotWidth / 2) * theme.scale} />
    </SVGBase>
  );
}

interface SVGBaseProps {
  isPast: boolean;
}

const SVGBase = styled.svg<SVGBaseProps>`
  transition: all 400ms ${ACCELERATE_EASING};
  fill: ${p => (p.isPast ? p.theme.contentColor : p.theme.detailColor)};
  opacity: ${p => (p.isPast ? p.theme.dimOpacity : 1)};
  overflow: visible;
`;
