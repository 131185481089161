import React, { useEffect, useRef } from "react";
import { Map, CameraOptions, Style } from "maplibre-gl";

import styled, { ThemeMode, UITheme } from "@style/theme";
import { useUITheme } from "src/hooks";

const WORLD_CAMERA: CameraOptions = {
  zoom: 2
};

type ModeMap = {
  [t in ThemeMode]: Style | string;
};

const tileMap: ModeMap = {
  day: "https://tile.livemap24.com/styles/style-light-vector.json",
  night: "https://tile.livemap24.com/styles/style-dark-vector.json"
};

export interface MaplibreProps {
  id: string;
  initialCamera?: CameraOptions;
  authToken?: string;
}

export interface MaplibreDispatch {
  /** Called once the map have been initialized, and are ready for use. */
  onReady?(map: Map): void;
  /** Called when the camera has stopped moving */
  onCameraMoved?(cameraOptions: CameraOptions): void;
  /** Called while the camera is moving */
  onCameraMove?(cameraOptions: CameraOptions): void;
}

const applyMapTheme = (theme: UITheme, map: Map) => {
  console.log("theme: " + tileMap[theme.mode]);
  map.setStyle(tileMap[theme.mode]);
};

function createMap(id: string, camera?: CameraOptions) {
  // Do this to avoid including maplibre-gl code in the bundle.
  // It is a peerDependency(and devDependency)
  return Promise.resolve(import("maplibre-gl")).then(
    (maplibregl) =>
      new maplibregl.Map({
        ...(camera ?? WORLD_CAMERA),
        style: tileMap["day"],
        container: id
      })
  );
}

type Props = MaplibreProps & MaplibreDispatch & React.PropsWithChildren<MaplibreProps>;

export function Maplibre({
  id,
  initialCamera,
  children,
  onReady,
  onCameraMove,
  onCameraMoved
}: Props) {
  const ref = useRef<Map | null>(null);
  const cameraRef = useRef<CameraOptions | null>(null);
  const theme = useUITheme();

  useEffect(() => {
    if (ref.current) return;

    createMap(id, initialCamera).then((map) => {
      ref.current = map;
      cameraRef.current = initialCamera || {};

      const cameraMove = () => {
        if (!cameraRef.current) return;

        const camera = cameraRef.current;

        camera.center = map.getCenter();
        camera.zoom = map.getZoom();
        camera.bearing = map.getBearing();
        camera.pitch = map.getPitch();

        onCameraMove && onCameraMove(camera);
      };

      const cameraMoveEnd = () =>
        cameraRef.current && onCameraMoved && onCameraMoved(cameraRef.current);

      map.on("move", cameraMove);
      map.on("dragend", cameraMoveEnd);
      map.on("zoomend", cameraMoveEnd);
      map.on("rotateend", cameraMoveEnd);
      map.on("load", () => onReady && onReady(map));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).maplibre = ref.current;
    });
  }, [id, initialCamera]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any).maplibre;
      ref.current?.remove();
    };
  }, []);

  useEffect(() => {
    ref.current && applyMapTheme(theme, ref.current);
  }, [theme]);

  return <MapContainer id={id}>{children}</MapContainer>;
}

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  outline: none !important;

  * {
    outline: none !important;
  }
`;
