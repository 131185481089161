import styled from "@style/theme";

/** A scrollable pane without a scrollbar */
export const ScrollPane = styled.div`
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
