import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import styled from "../style/theme";
import { px } from "../style/scaling";
import { FloatingPane, RIGHT_CORNERS } from "../panel";
import { ToastAction, ToastActions } from "./ToastActions";
import { paneBoxShadow } from "@style/shadow";

export type ToastType = "danger" | "success" | "info";

export interface ToastProps {
  title?: string;
  /** The type fo toast, "danger", "success" or "info". Default is info.  */
  type?: ToastType;
  /** Actions to display at the bottom of the toast, below its children. */
  actions?: ToastAction[];
}

type Props = PropsWithChildren<ToastProps>;

export function Toast({ title, type = "info", actions, children }: Props) {
  const classes = classNames(type);

  return (
    <Container corners={RIGHT_CORNERS} className={classes}>
      {title ? <Title>{title}</Title> : null}
      {children}
      <ToastActions actions={actions} />
    </Container>
  );
}

const Title = styled.h4`
  font-family: ${(p) => p.theme.headingFontFamily};
  font-size: ${px(14)};
  font-weight: 500;
  text-transform: uppercase;
  opacity: ${(p) => p.theme.disabledOpacity};
  margin-bottom: ${px(3)};
`;

const Container = styled(FloatingPane)`
  padding: ${px(6, 12)};
  font-size: ${px(16)};
  max-width: 280px;

  &.info {
    border-left: ${px(6)} solid ${(p) => p.theme.detailColor};
    box-shadow: ${paneBoxShadow()}, -22px 0 24px -22px ${(p) => p.theme.detailColor};
  }

  &.danger {
    border-left: ${px(6)} solid ${(p) => p.theme.dangerColor};
    box-shadow: ${paneBoxShadow()}, -22px 0 24px -22px ${(p) => p.theme.dangerColor};
  }

  &.success {
    border-left: ${px(6)} solid ${(p) => p.theme.successColor};
    box-shadow: ${paneBoxShadow()}, -22px 0 24px -22px ${(p) => p.theme.successColor};
  }
`;
