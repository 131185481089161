import styled, { ThemedStyledInterface } from "styled-components";

const CONTENT_FONT_STACK =
  "Barlow, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol";

const HEADING_FONT_STACK =
  "Barlow Semi Condensed, Roboto Condensed, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol";

export type ThemeMode = "day" | "night";

interface ColorTheme {
  /** Whether the theme is intended for night, or day mode. */
  mode: ThemeMode;
  /** The base background color of the theme, used for e.g. panes. */
  backgroundColor: string;
  /** The alternative background color of the theme, used for e.g. hovered buttons. */
  altBackgroundColor: string;
  /** The color used for content, e.g text and, SVG fills/strokes. */
  contentColor: string;
  /**
   * An accent color for details, which is used for e.g content which is hovered.
   * This color is usually a bit scream~ish as it is meant to stand out.
   */
  detailColor: string;
  /** The color which is used for e.g panel and button borders. */
  borderColor: string;
  /** The color to to use for dangerous actions, errors et al.  */
  dangerColor: string;
  /** The color to use for favorable outcomes.  */
  successColor: string;
  /** A content color which is always near black. */
  darkColor: string;
  /** A content color which is always near white. */
  lightColor: string;
  /** The opacity to use for elements which are considered "disabled". */
  disabledOpacity: number;
  /**
   * The opacity to use for elements which are considered "dim".
   * That is, "more toned down than disabled".
   */
  dimOpacity: number;
}

interface FontTheme {
  /** The font which is used for text content. */
  contentFontFamily: string;
  /** The font which is used for headings & labels. */
  headingFontFamily: string;
}

interface UnitTheme {
  /**
   * "The pixel size, in pixels".
   * The default value is 1, which is suitable for many desktop and mobile applications.
   * Many things in the UI conform to this, such as sizes and offsets.
   */
  scale: number;
  /** The standard border radius, used for e.g panel, and button corners */
  borderRadius: number;
}

export type UITheme = ColorTheme & FontTheme & UnitTheme;

const BASE_THEME: UnitTheme & FontTheme = {
  contentFontFamily: CONTENT_FONT_STACK,
  headingFontFamily: HEADING_FONT_STACK,
  borderRadius: 6,
  scale: 1
};

export const DEFAULT_DAY_THEME: UITheme = {
  ...BASE_THEME,
  mode: "day",
  backgroundColor: "#FFFFFF",
  altBackgroundColor: "#F5F5F5",
  contentColor: "#060F19",
  detailColor: "#187ADC",
  borderColor: "#EAEAEA",
  dangerColor: "#DC1818",
  successColor: "#18DC46",
  darkColor: "#060F19",
  lightColor: "#EDF5FD",
  disabledOpacity: 0.6,
  dimOpacity: 0.3
};

export const DEFAULT_NIGHT_THEME: UITheme = {
  ...BASE_THEME,
  mode: "night",
  backgroundColor: "#000000",
  altBackgroundColor: "#0F0F0F",
  contentColor: "#FBEFF3",
  detailColor: "#DC1860",
  borderColor: "#1C1C1C",
  dangerColor: "#DC1818",
  successColor: "#18DC46",
  darkColor: "#141414",
  lightColor: "#EFEFEF",
  disabledOpacity: 0.6,
  dimOpacity: 0.3
};

export default styled as ThemedStyledInterface<UITheme>;
