import React, {
  useState,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  ReactNode,
  ReactElement
} from "react";

import { SwipeCallback, useSwipeable } from "react-swipeable";

import styled from "@style/theme";

import { noop } from "@util/noop";
import { FloatingPane, TOP_CORNERS } from "../panel";
import { STANDARD_EASING } from "@style/easing";
import { CSSProperties } from "styled-components";

export enum BottomSheetSize {
  CLOSED,
  MINIMIZED,
  EXPANDED,
  FULL
}

export interface BottomSheetProps {
  /** The desired initial size. */
  initialSize?: BottomSheetSize;
  /**
   * How many pixels the bottom sheet has to be swiped
   * vertically before it will change state from e.g. minimized to expanded.
   */
  deadzone?: number;
  /** The height of the sheet when it's in the "expanded" state, i.e: swiped "up" once */
  expandedHeight?: number;
  /** The height of the sheet when it's in the "full" state, i.e: swiped "up" twice */
  fullHeight?: number;
  /** How many milliseconds it should take for the bottom sheet to animate between different sizes */
  transitionDuration?: number;
}

export interface BottomSheetDispatch {
  /** Called when the bottom sheet changes size. */
  onSizeChanged?(height: number, size: BottomSheetSize | null): void;
}

type SwipeDir = "Up" | "Down" | "Left" | "Right";

const getHeaderChild = (children: ReactNode): ReactElement | null => {
  if (children instanceof Array && children.length) {
    return (children[0] as ReactElement) || null;
  }

  return (children as ReactElement) || null;
};

const getChildren = (children: ReactNode): ReactNode | null => {
  if (children instanceof Array && children.length) {
    return children.slice(1);
  }

  return null;
};

const getHeightFromSize = (
  size: BottomSheetSize,
  expandedHeight: number,
  fullHeight: number
): number =>
  size === BottomSheetSize.FULL
    ? fullHeight
    : size === BottomSheetSize.EXPANDED
    ? expandedHeight
    : 0;

type AllBottomSheetProps = PropsWithChildren<BottomSheetProps & BottomSheetDispatch>;

/** The minimum amount which needs to be swiped in order for the height to update. */
const EPSILON = 0.5;

const DEFAULT_TRANSITION_DURATION = 240;

export function BottomSheet({
  initialSize = BottomSheetSize.MINIMIZED,
  children: originalChildren,
  deadzone = 40,
  expandedHeight = 240,
  transitionDuration = DEFAULT_TRANSITION_DURATION,
  fullHeight = window.innerHeight,
  onSizeChanged = noop
}: AllBottomSheetProps) {
  const header = getHeaderChild(originalChildren);
  const children = getChildren(originalChildren);

  if (!header) {
    return null;
  }

  const [size, setSize] = useState(initialSize);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(
    getHeightFromSize(size, expandedHeight, fullHeight)
  );
  const [direction, setDirection] = useState<SwipeDir | null>(null);
  const [isSwiping, setIsSwiping] = useState(false);

  const isSwipedOver = (point: number, dz: number = deadzone): boolean =>
    direction === "Up" && contentHeight > point + dz;

  const isSwipedBelow = (point: number, dz: number = deadzone): boolean =>
    direction === "Down" && contentHeight < point - dz;

  /** Animates the bottom sheet to a new size. */
  const resizeTo = (newSize: BottomSheetSize) => {
    const newHeight = getHeightFromSize(newSize, expandedHeight, fullHeight);

    setSize(newSize);
    setContentHeight(newHeight);
  };

  /** Expands the sheet if minimized, otherwise minimizes it. */
  const toggleExpanded = () =>
    size === BottomSheetSize.MINIMIZED
      ? resizeTo(BottomSheetSize.EXPANDED)
      : resizeTo(BottomSheetSize.MINIMIZED);

  /** Called "while" the user is swiping */
  const onSwiping: SwipeCallback = ({ deltaY, dir }) => {
    const height =
      size !== BottomSheetSize.CLOSED
        ? getHeightFromSize(size, expandedHeight, fullHeight)
        : 0;

    const newHeight = height + deltaY;

    if (Math.abs(height - newHeight) > EPSILON) {
      setContentHeight(newHeight);

      if (!isSwiping) {
        setIsSwiping(true);
      }
      if (dir !== direction) {
        setDirection(dir);
      }
    }
  };

  /** Called once the user is done swiping */
  const onSwiped: SwipeCallback = ({ dir }) => {
    setDirection(dir);
    setIsSwiping(false);

    if (isSwipedOver(expandedHeight)) {
      resizeTo(BottomSheetSize.FULL);
    } else if (isSwipedOver(0, 0)) {
      resizeTo(BottomSheetSize.EXPANDED);
    } else if (isSwipedBelow(expandedHeight)) {
      resizeTo(BottomSheetSize.MINIMIZED);
    } else if (isSwipedBelow(fullHeight)) {
      resizeTo(BottomSheetSize.EXPANDED);
    } else {
      resizeTo(BottomSheetSize.FULL);
    }
  };

  useLayoutEffect(() => {
    if (headerElement !== null) {
      setHeaderHeight(headerElement.clientHeight);
    }
  }, [headerElement]);

  // This effect runs when `size` changes.
  useEffect(() => {
    setTimeout(() => {
      onSizeChanged(contentHeight, initialSize);
    }, transitionDuration);
  }, [size]);

  const swipeProps = useSwipeable({
    onSwiping,
    onSwiped,
    preventDefaultTouchmoveEvent: true
  });

  if (size === BottomSheetSize.CLOSED) {
    return null;
  }

  const headerWithHandler = React.cloneElement(header, {
    ...swipeProps,
    onClick: toggleExpanded,
    ref(element: HTMLElement) {
      setHeaderElement(element);
      swipeProps.ref(element);
    }
  });

  const sheetStyle = {
    height: headerHeight + contentHeight + "px",
    maxHeight: fullHeight + "px",
    minHeight: headerHeight + "px",
    transition: isSwiping ? "none" : `height ${transitionDuration}ms ${STANDARD_EASING}`
  } as CSSProperties;

  return (
    <SheetPane corners={TOP_CORNERS} style={sheetStyle}>
      {headerWithHandler}
      {children}
    </SheetPane>
  );
}

const SheetPane = styled(FloatingPane)`
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  will-change: height, transition;
  overflow: hidden;
`;
