import { px } from "@style/scaling";
import styled from "@style/theme";

export const Legend = styled.h4`
  font-weight: 500;
  font-size: ${px(16)};
  margin-bottom: ${px(14)};
`;

export const InputLabel = styled.label`
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: ${px(18)};
  text-indent: ${px(4)};

  > span {
    opacity: ${p => p.theme.disabledOpacity};
    font-size: ${px(14)};
    text-transform: uppercase;
    font-family: ${p => p.theme.headingFontFamily};
    font-weight: 500;
    transition: all 320ms ease-out;
  }

  &:focus-within > span {
    color: ${p => p.theme.detailColor};
    opacity: 1;
  }
`;
