import { ThemeProps } from "styled-components";

import { UITheme } from "./theme";

const PANE_BOX_SHADOW_NIGHT = (strength = 0.4) =>
  `0 0 18px -2px rgba(0, 0, 0, ${strength})`;

const PANE_BOX_SHADOW_DAY = (strength = 0.4) =>
  `0 0 22px -8px rgba(0, 0, 0, ${strength})`;

export const paneBoxShadow =
  (strength = 0.4) =>
  (props: ThemeProps<UITheme>) =>
    props.theme.mode === "night"
      ? PANE_BOX_SHADOW_NIGHT(strength)
      : PANE_BOX_SHADOW_DAY(strength);
