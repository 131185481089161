import React, { Fragment, useRef, useEffect } from "react";
import styled from "@style/theme";
import { px } from "@style/scaling";
import { STANDARD_EASING } from "@style/easing";

export interface RadioBoxValues {
  [value: string]: string;
}

export interface InlineRadioBoxProps<V extends RadioBoxValues> {
  values: V;
  value: keyof V | null;
}

export interface InlineRadioBoxDispatch<V extends RadioBoxValues> {
  onChange(value: keyof V): void;
}

type Props<V extends RadioBoxValues> = InlineRadioBoxProps<V> & InlineRadioBoxDispatch<V>;

const generateId = () => "__" + Math.round(Math.random() * 1000000).toString(16);

export function InlineRadioBox<V extends RadioBoxValues>({
  values,
  value: selectedValue,
  onChange
}: Props<V>) {
  const idRef = useRef(generateId());

  useEffect(() => {
    idRef.current = generateId();
  }, []);

  const items = Object.keys(values).map(value => (
    <Fragment key={value}>
      <input
        type="radio"
        required={true}
        id={`${idRef.current}_${value}`}
        value={value}
        checked={value === selectedValue}
        onChange={() => onChange(value)}
      />
      <label htmlFor={`${idRef.current}_${value}`}>{values[value]}</label>
    </Fragment>
  ));

  return <Wrapper>{items}</Wrapper>;
}

const Wrapper = styled.div`
  margin: ${px(8, 0, 20, 0)};
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 2px solid ${p => p.theme.detailColor};
  overflow: hidden;

  > label {
    margin: auto;
    flex: 1;
    padding: ${px(8)};
    text-transform: uppercase;
    font-weight: 700;
    font-size: ${px(16)};
    font-family: ${p => p.theme.headingFontFamily};
    border-right: 2px solid ${p => p.theme.detailColor};
    color: ${p => p.theme.detailColor};
    text-align: center;
    height: 100%;
    transition: all 200ms ${STANDARD_EASING};
  }

  > input[type="radio"] {
    display: none;
  }

  > input[type="radio"]:checked + label {
    background-color: ${p => p.theme.detailColor};
    color: ${p => p.theme.altBackgroundColor};
  }

  > label:last-child {
    border: none;
  }
`;
