import { px } from "@style/scaling";
import styled from "@style/theme";
import { paneBoxShadow } from "@style/shadow";

export interface ButtonStyleProps {
  secondary?: boolean;
  block?: boolean;
}

export const Button = styled.button<ButtonStyleProps>`
  display: ${p => (p.block ? "block" : "flex")};
  color: ${p => (p.secondary ? p.theme.contentColor : p.theme.detailColor)};
  opacity: ${p => (p.secondary ? p.theme.disabledOpacity : 1)};
  font-weight: ${p => (p.secondary ? 500 : 700)};
  font-family: ${p => p.theme.headingFontFamily};
  width: ${p => (p.block ? "100%" : "auto")};

  text-align: center;
  padding: ${px(10, 16)};
  transition: all 0.2s ease-out;
  text-transform: uppercase;
  background: transparent;
  font-size: ${px(20)};
  border: none;
  border: 1px solid;
  border-radius: ${px(2)};

  &:active {
    border-color: ${p => p.theme.detailColor};
  }

  &:disabled {
    border: ${px(2)} solid transparent;
    color: ${p => p.theme.contentColor};
    opacity: ${p => p.theme.dimOpacity};
    background-color: transparent;
    box-shadow: none;
  }
`;

export const CTAButton = styled.button<ButtonStyleProps>`
  display: ${p => (p.block ? "block" : "flex")};
  flex: 1;
  background-color: ${p => (p.secondary ? p.theme.contentColor : p.theme.detailColor)};
  opacity: ${p => (p.secondary ? p.theme.disabledOpacity : 1)};
  font-weight: ${p => (p.secondary ? 500 : 700)};
  font-family: ${p => p.theme.headingFontFamily};
  width: ${p => (p.block ? "100%" : "auto")};
  justify-content: center;
  text-align: center;
  padding: ${px(10, 16)};
  transition: all 0.2s ease-out;
  text-transform: uppercase;
  color: ${p => p.theme.backgroundColor};
  font-size: ${px(20)};
  border: none;
  box-shadow: ${paneBoxShadow()};
  border-radius: ${px(6)};
  user-select: none;

  &:active {
    border-color: ${p => p.theme.detailColor};
  }

  &:disabled {
    border: ${px(2)} solid transparent;
    color: ${p => p.theme.contentColor};
    opacity: ${p => p.theme.dimOpacity};
    background-color: transparent;
    box-shadow: none;
  }
`;

export const LinkButton = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-family: ${p => p.theme.headingFontFamily};
  color: ${p => p.theme.contentColor};
  opacity: ${p => p.theme.disabledOpacity};
  text-transform: uppercase;
  font-size: ${px(18)};
`;

export const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 500;
  font-size: ${px(18)};
  font-family: ${p => p.theme.headingFontFamily};
  user-select: none;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;
