import React, { HTMLAttributes } from "react";

import styled, { UITheme } from "@style/theme";

import { paneBoxShadow } from "@style/shadow";

import { Pane } from "./Pane";
import { NO_CORNERS, CornerArray } from "./corners";

export interface FloatingPaneProps extends HTMLAttributes<HTMLDivElement> {
  corners?: CornerArray;
}

type P = FloatingPaneProps & { corners: CornerArray; theme: UITheme };

const PaneWithCorners = styled(Pane)`
  box-shadow: ${paneBoxShadow()};
  border-top-left-radius: ${(p: P) => (p.corners[0] ? p.theme.borderRadius : 0)}px;
  border-top-right-radius: ${(p: P) => (p.corners[1] ? p.theme.borderRadius : 0)}px;
  border-bottom-right-radius: ${(p: P) => (p.corners[2] ? p.theme.borderRadius : 0)}px;
  border-bottom-left-radius: ${(p: P) => (p.corners[3] ? p.theme.borderRadius : 0)}px;
`;

/**
 * A pane which provides a box shadow by default.
 */
export function FloatingPane({ corners = NO_CORNERS, ...props }: FloatingPaneProps) {
  return <PaneWithCorners corners={corners} {...props} />;
}
