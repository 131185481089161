/**
 * Defines an array which specify each corner of a rectangle.
 *
 * `[topLeft, topRight, bottomRight, bottomLeft]`
 */
export type CornerArray = [boolean, boolean, boolean, boolean];

/** Defines no corners */
export const NO_CORNERS: CornerArray = [false, false, false, false];
/** Defines the top two corners */
export const TOP_CORNERS: CornerArray = [true, true, false, false];
/** Defines the two right corners. */
export const RIGHT_CORNERS: CornerArray = [false, true, true, false];
/** Defines the two left corners. */
export const LEFT_CORNERS: CornerArray = [true, false, false, true];
/** Defines the bottom two corners */
export const BOTTOM_CORNERS: CornerArray = [false, false, true, true];
/** Defines all four corners. */
export const ALL_CORNERS: CornerArray = [true, true, true, true];
