/**
 * Provides static functions for handling time.
 * * * *
 */

/** Converts seconds to milliseconds */
export const seconds = (s: number) => s * 1000;

/** Converts minutes to milliseconds */
export const minutes = (m: number) => seconds(60 * m);

/** Converts hours to milliseconds */
export const hours = (h: number) => minutes(60 * h);

/** Converts hours, minutes and seconds to milliseconds */
export const millis = (h: number, m: number, s = 0) => hours(h) + minutes(m) + seconds(s);

/** Gets the time of day from a given date in UTC. */
export const timeOfDay = (date: Date): number =>
  millis(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

/** Converts a unix timestamp to time of day. */
export const fromUnix = (timestamp: number) => timeOfDay(new Date(timestamp));

/** Gets the number of milliseconds since midnight UTC. */
export const currentTime = (): number => fromUnix(new Date().getTime());

/** The UTC offset in milliseconds as reported by the browser. */
export const UTCOffset = new Date().getTimezoneOffset() * 60000;

/** Returns a unix timestamp for today at the specified time. */
export const todayAt = (time: number): number =>
  new Date().setUTCHours(0, 0, 0, 0) + time;

/** Formats the provided time as HH:MM[:SS] */
export const formatTime = (time: number, formatSecs = false, clockLike = false) => {
  const pad = (num: number) => (num < 10 ? "0" + num : num.toString());

  const sign = time < 0 ? "-" : "";

  let t = Math.abs(time);
  const ms = t % 1000;
  t = (t - ms) / 1000;
  const secs = t % 60;
  t = (t - secs) / 60;
  const mins = t % 60;
  let hrs = (t - mins) / 60;

  if (clockLike && hrs === 24) {
    hrs = 0;
  }

  if (formatSecs) {
    return `${sign}${pad(hrs)}:${pad(mins)}:${pad(secs)}`;
  }

  return sign + pad(hrs) + ":" + pad(mins);
};

export const formatClock = (time: number, formatSecs = false) => {
  const wrapped = time % hours(24);

  return formatTime(wrapped, formatSecs);
};

/** Parses a time string in the format HH:MM[:SS] */
export const parseTime = (timeStr: string): number => {
  const parts = timeStr.split(":");
  const h = Math.abs(Number(parts[0]));
  const m = Math.abs(Number(parts[1]));
  const s = Math.abs(Number(parts[2]) || 0);

  if (isNaN(h) || isNaN(m)) {
    throw new Error(`Invalid time format '${timeStr}'. Expected HH:MM[:SS]`);
  }

  const sign = timeStr[0] === "-" ? -1 : 1;

  return sign * millis(h, m, s);
};

/** Determines wether the provided time is past the current time. */
export const isPast = (time: number): boolean => time < currentTime();

/** Converts a UTC time to local time using the browser's UTC offset. */
export const toLocalTime = (time: number): number => time - UTCOffset;
