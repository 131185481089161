import React, { PropsWithChildren } from "react";

import { px } from "@style/scaling";
import styled from "@style/theme";

import { ButtonProps } from "./button";

import { paneBoxShadow } from "@style/shadow";

export function ButtonGroup({ children, ...props }: PropsWithChildren<ButtonProps>) {
  return <Group {...props}>{children}</Group>;
}

export const Group = styled.div`
  box-shadow: ${paneBoxShadow()};
  display: inline-flex;
  flex-direction: column;
  border-radius: ${({ theme }) => px(theme.borderRadius)};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.borderColor};

  > * {
    box-shadow: none;
    border-width: 0;
    border-radius: 0;
    border-top-width: 1px;
  }

  > *:first-child {
    border-top-width: 0;
  }
`;
