import React from "react";

import { formatTime, fromUnix } from "@util/time";

import { px } from "@style/scaling";
import styled from "@style/theme";

export interface StopDepartureProps {
  /**
   * The name the location the departure is towards.
   * This is usually the headsign displayed on the vehicle
   * or the name of the end-station.
   */
  towards: string;
  /** The time of departure, a unix timestamp in milliseconds. */
  departsAt: number;
}

/** Displays a departure from a stop towards an end-station. */
export function StopDeparture({ towards, departsAt }: StopDepartureProps) {
  const dateTime = new Date(departsAt).toISOString();
  const departureTime = formatTime(fromUnix(departsAt));

  return (
    <Container>
      <time title={dateTime}>{departureTime}</time>
      <TowardsIcon />
      <div>{towards}</div>
    </Container>
  );
}

const Container = styled.div`
  font-family: ${p => p.theme.headingFontFamily};
  font-size: ${px(18)};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${px(46)};
`;

const TowardsIcon = styled.figure`
  width: 0;
  height: 0;
  margin: ${px(2, 8, 0, 10)};
  border-top: ${px(5)} solid transparent;
  border-bottom: ${px(5)} solid transparent;
  border-left: ${px(8)} solid ${p => p.theme.detailColor};
`;
