import { TimingFunction } from "csstype";

import { ACCELERATE_EASING, DECELERATE_EASING } from "@style/easing";
import { clamp } from "@util/math";

/** Defines which swipe phase (or state) the component is in */
export const enum SwipePhase {
  /** The component is closed, and nothing should be rendered */
  CLOSED,
  /** The component is opened */
  OPEN,
  /** The component is transitioning to an open phase */
  CLOSING,
  /** The component is transitioning to a closed phase */
  OPENING,
  /** The component is being swiped by the user  */
  BEING_SWIPED
}

/** The upper limit for the swipe velocity */
const MAX_VELOCITY = 1.1;

/** The lower limit for the swipe velocity */
const MIN_VELOCITY = 0.9;

/**
 * Returns the appropriate easing (or timing)
 * function at a given swipe phase.
 *
 * See: https://material.io/design/motion/speed.html#easing
 */
export function getEasingAtPhase(phase: SwipePhase): TimingFunction {
  switch (phase) {
    // The element is "incoming"
    case SwipePhase.CLOSED:
    case SwipePhase.OPENING:
    default:
      return DECELERATE_EASING;

    // The element is "exiting"
    case SwipePhase.OPEN:
    case SwipePhase.CLOSING:
      return ACCELERATE_EASING;
  }
}

export const clampSwipeVelocity = (velocity: number) =>
  clamp(velocity, MIN_VELOCITY, MAX_VELOCITY);
