import React from "react";
import { Map } from "maplibre-gl";

import styled from "@style/theme";

import { plusIcon, minusIcon } from "./icons";
import { ButtonGroup } from "./ButtonGroup";
import { SquareButton } from "./SquareButton";

export interface ZoomButtonsProps {
  map: Map;
}

export function ZoomButtons({ map }: ZoomButtonsProps) {
  return (
    <ButtonGroup>
      <ZoomButton onClick={() => map?.zoomIn()}>{plusIcon}</ZoomButton>
      <ZoomButton onClick={() => map?.zoomOut()}>{minusIcon}</ZoomButton>
    </ButtonGroup>
  );
}

const ZoomButton = styled(SquareButton)`
  > svg {
    padding: 4px;
    width: 24px;
    height: 24px;
  }
`;
