import React, { PropsWithChildren, CSSProperties } from "react";
import styled, { ThemeProvider } from "styled-components";

import {
  ThemeMode,
  UITheme,
  DEFAULT_DAY_THEME,
  DEFAULT_NIGHT_THEME
} from "./style/theme";

export interface UIRootProps {
  mode?: ThemeMode;
  dayTheme?: UITheme;
  nightTheme?: UITheme;
  className?: string;
  style?: CSSProperties;
}

export function LivemapUI({
  mode = "day",
  dayTheme = DEFAULT_DAY_THEME,
  nightTheme = DEFAULT_NIGHT_THEME,
  className,
  style,
  children
}: PropsWithChildren<UIRootProps>) {
  const theme = mode === "day" ? dayTheme : nightTheme;

  return (
    <ThemeProvider theme={theme}>
      <Root className={className} style={style}>
        {children}
      </Root>
    </ThemeProvider>
  );
}

const Root = styled.div`
  font: normal 400 16px/1.4 ${p => p.theme.contentFontFamily};
  background-color: ${p => p.theme.altBackgroundColor};
  color: ${p => p.theme.contentColor};
  fill: currentColor;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: inherit;
    font: inherit;
    font-family: inherit;
    fill: inherit;
  }
`;
