import classNames from "classnames";
import React, { useEffect, useState, CSSProperties } from "react";
import { Map } from "maplibre-gl";

import styled from "@style/theme";
import { px } from "@style/scaling";
import { paneBoxShadow } from "@style/shadow";

export interface CompassProps {
  map: Map;
}

export function CompassButton({ map }: CompassProps) {
  const [bearing, setBearing] = useState(Math.round(map.getBearing() || 0));
  const [pitch, setPitch] = useState(Math.round(map.getPitch() || 0));

  useEffect(() => {
    const update = () => {
      setBearing(Math.round(map.getBearing() || 0));
      setPitch(Math.round(map?.getPitch() || 0));
    };

    map.on("rotate", update);
    map.on("pitch", update);
    map.once("load", update);

    return () => {
      map.off("rotate", update);
      map.off("pitch", update);
    };
  }, [map, setBearing, setPitch]);

  const resetRotation = () =>
    map.flyTo({
      pitch: 0,
      bearing: 0,
      maxDuration: 500
    });

  const classes = classNames({
    isHidden: bearing === 0 && pitch === 0
  });

  const compassStyle: CSSProperties = {
    transform: `rotateX(${Math.min(40, pitch * 0.9)}deg)`,
    borderBottomWidth: `${Math.min(40, pitch) / 6}px`
  };

  const needleStyle: CSSProperties = {
    transform: `rotateZ(${bearing}deg)`
  };

  return (
    <Button onClick={resetRotation} style={compassStyle} className={classes}>
      <svg viewBox="0 0 24 24" style={needleStyle}>
        <path d="M 9 12 l 3 -12 3 12z" className="north" />
        <path d="M 9 12 l 3 12 3 -12z" className="south" />
      </svg>
    </Button>
  );
}

const Button = styled.button`
  background-color: ${(p) => p.theme.backgroundColor};
  border: ${px(1)} solid ${(p) => p.theme.borderColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px(42)};
  height: ${px(42)};
  box-shadow: ${paneBoxShadow()};
  outline: none !important;

  &.isHidden {
    transform: scale(0.2);
    opacity: 0;
    pointer-events: none;
  }

  &:hover,
  &:active {
    transition: border-bottom-width 200ms ease-in, transform 200ms ease-out;
    transform: none !important;
    border-bottom-width: ${px(1)} !important;

    > svg {
      transition: all 400ms 160ms ease-out;
      transform: none !important;
    }
  }

  &:active {
    background-color: ${(p) => p.theme.altBackgroundColor};
  }

  > svg {
    width: 24px;
    height: 24px;

    .north {
      fill: ${(p) => p.theme.dangerColor};
    }
  }
`;
