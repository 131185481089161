import { ThemeProps } from "styled-components";
import { UITheme } from "./theme";

function pxString(units: number[], scale: number) {
  if (units.length === 1) {
    return Math.round(units[0] * scale) + "px";
  }

  return units.map(u => Math.round(u * scale) + "px").join(" ");
}

/**
 * Returns a function which converts units to pixels,
 * and appends, `"px"` afterwards. The returned function returns whole pixels.
 *
 ** **Example usage**
 * ```
 * const Thing = styled.div`
 *   padding: ${px(12, 24, 0, 2)};
 * `;
 * ```
 *
 * Is equivalent to to the following CSS, given that `scale` is set to `1`.
 *
 * ```
 * const Thing = styled.div`
 *   padding: 12px 24px 0px 2px;
 * `;
 * ```
 *
 * You want to use this for things in the UI which should scale in accordance with the theme, such as:
 *  `padding`, `margin`, `border-radius`, etc &hellip;
 *
 * @param units One or more units to convert to pixels
 */
export const px = (...units: number[]) => (props: ThemeProps<UITheme>) =>
  pxString(units, props.theme.scale);
