import { InputLabel } from "./heading";
import React, {
  ReactNode,
  PropsWithChildren,
  forwardRef,
  InputHTMLAttributes
} from "react";

import styled from "@style/theme";
import { px } from "@style/scaling";
import { noop } from "@util/noop";

interface TextInputDispatch {
  onTextChange?(text: string): void;
}

interface TextInputProps {
  label: ReactNode;
  /** Visibly hidden, but still in the dom. */
  hidden?: boolean;
}

type Props = PropsWithChildren<
  TextInputDispatch & TextInputProps & InputHTMLAttributes<HTMLInputElement>
>;

export const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ onTextChange = noop, label, hidden, children, ...inputProps }, ref) => (
    <InputLabel style={hidden ? { display: "none" } : undefined}>
      <span>{label}</span>
      <Input
        ref={ref}
        onChange={(event) => {
          onTextChange(event.target.value);
          inputProps.onChange && inputProps.onChange(event);
        }}
        {...inputProps}
      />
      {children}
    </InputLabel>
  )
);

const Input = styled.input`
  margin-top: ${px(8)};
  line-height: 1;
  display: block;
  padding: ${px(12, 12, 16)};
  width: 100%;
  font-size: ${px(20)};
  border: 1px solid ${(p) => p.theme.borderColor};
  background-color: ${(p) => p.theme.altBackgroundColor};
  font-family: Barlow, Roboto, sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in;
  outline: none;

  &:focus {
    outline: ${px(1)} solid ${(p) => p.theme.detailColor};
  }
`;
