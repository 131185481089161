import styled from "@style/theme";

import { STANDARD_EASING } from "@style/easing";

/**
 * A very basic component which provides a background from the theme.
 * The pane is positioned `relative` as default.
 *
 * See also: `<FloatingPane>`
 */
export const Pane = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundColor};
  transition: background-color 300ms ${STANDARD_EASING};
`;
