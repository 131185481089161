import React, { forwardRef, SelectHTMLAttributes } from "react";

import styled from "@style/theme";
import { px } from "@style/scaling";
import { noop } from "@util/noop";

export interface SelectBoxDispatch {
  onValueChange?(value: string): void;
}

type Props = SelectBoxDispatch & SelectHTMLAttributes<HTMLSelectElement>;

export const SelectBox = forwardRef<HTMLSelectElement, Props>(
  ({ onValueChange = noop, ...selectProps }, ref) => (
    <SelectContainer>
      <Select
        ref={ref}
        onChange={(event) => {
          onValueChange(event.target.value);
          selectProps.onChange && selectProps.onChange(event);
        }}
        {...selectProps}
      />
    </SelectContainer>
  )
);

const arrowSVG = (fill: string) =>
  `data:image/svg+xml;utf8,<svg style='opacity: 0.5' width='8' height='8' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='${encodeURIComponent(
    fill
  )}' d='M12 21l-12-18h24z'/></svg>`;

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  border: 1px solid ${(p) => p.theme.borderColor};

  &::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    content: url("${(p) => arrowSVG(p.theme.contentColor)}");
    height: 100%;
    top: 0;
    right: 0;
    width: ${px(30)};
    background-color: ${(p) => p.theme.altBackgroundColor};
    pointer-events: none;
    border-left: 1px solid ${(p) => p.theme.borderColor};
  }
`;

const Select = styled.select`
  border: none;
  position: relative;
  appearance: none;
  outline: none !important;
  color: ${(p) => p.theme.contentColor};
  width: 100%;
  padding: ${px(8, 12)};
  background-color: ${(p) => p.theme.backgroundColor};
`;
