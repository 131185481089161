import React from "react";
import styled from "../style/theme";
import { px } from "../style/scaling";
import { ActionButton } from "../form";

export type ToastActionType = "danger" | "success" | "default";

export interface ToastAction {
  /** The text to click */
  text: string;
  /** What happens when you click the toast. */
  onClick(): void;
}

export interface ToastActionProps {
  actions?: ToastAction[];
}

export function ToastActions({ actions }: ToastActionProps) {
  if (!actions) {
    return null;
  }

  const createButton = ({ text, onClick }: ToastAction) => (
    <Button key={text} onClick={onClick}>
      {text}
    </Button>
  );

  return <Container>{actions.map(createButton)}</Container>;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${px(6)};
`;

const Button = styled(ActionButton)`
  color: inherit;
  margin: ${px(2, 6)};
`;
