/*
 * These easing functions are provided by Google's material design.
 * See: https://material.io/design/motion/speed.html#easing
 */

/**
 * Standard easing puts subtle attention at the end of an animation,
 * by giving more time to deceleration than acceleration. It is the most common form of easing.
 *
 * Elements that begin and end at rest use standard easing.
 * They speed up quickly and slow down gradually, in order to emphasize the end of the transition.
 */
export const STANDARD_EASING = "cubic-bezier(0.4, 0, 0.2, 1)";

/**
 * Incoming elements are animated using deceleration easing,
 * which starts a transition at peak velocity (the fastest point of an element’s movement) and ends at rest.
 */
export const DECELERATE_EASING = "cubic-bezier(0.0, 0.0, 0.2, 1)";

/**
 * Elements exiting a screen use acceleration easing,
 * where they start at rest and end at peak velocity.
 */
export const ACCELERATE_EASING = "cubic-bezier(0.4, 0.0, 1, 1)";
