import React from "react";

import { formatTime, fromUnix } from "@util/time";

import { px } from "@style/scaling";
import styled, { UITheme } from "@style/theme";

import { TripStopIndicator } from "./TripStopDepartureIndicator";
import { StopPosition } from "./stops";
import { ThemeContext } from "styled-components";

export interface TripStopDepartureProps {
  /** The name of the stop. */
  name: string;
  /** The scheduled time of departure, a unix timestamp in milliseconds. */
  departsAt: number;
  /** Whether or not this stop has been passed. */
  isPassed?: boolean;
  /** Whether or not this stop is the first, last or somewhere in the middle. */
  position?: StopPosition;
  /** The delay (in milliseconds) to this stop. */
  delay?: number | null;
  /** Overrides the detail color of the line, to this color */
  lineColor?: string;
}

/** Displays the departure from a stop in a trip. */
export function TripStopDeparture({
  name,
  departsAt,
  delay,
  position,
  isPassed,
  lineColor
}: TripStopDepartureProps) {
  const actualDeparture = departsAt + (delay || 0);
  const scheduledTime = formatTime(fromUnix(departsAt));
  const departureTime = formatTime(fromUnix(actualDeparture));
  const dateTime = new Date(actualDeparture).toLocaleString();
  const isDelayed = scheduledTime !== departureTime;

  return (
    <ThemeContext.Consumer>
      {(theme: UITheme) => (
        <Container>
          <Times>
            <DepartureTime title={dateTime}>{departureTime}</DepartureTime>
            {isDelayed ? <OldTime>{scheduledTime}</OldTime> : null}
          </Times>
          <TripStopIndicator
            theme={theme}
            position={position}
            disabled={isPassed}
            lineColor={lineColor}
          />
          <StopName>{name}</StopName>
        </Container>
      )}
    </ThemeContext.Consumer>
  );
}

const Container = styled.div`
  font-family: ${p => p.theme.headingFontFamily};
  font-size: ${px(18)};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${px(46)};
`;

const Times = styled.div`
  flex: 0 0 ${px(50)};
  min-width: ${px(50)};
  max-width: ${px(50)};
  font-family: inherit;
  display: flex;
  flex-direction: column;
`;

const DepartureTime = styled.time`
  font-size: ${px(18)};
  font-weight: 500;
  font-family: inherit;
`;

const OldTime = styled.time`
  position: relative;
  font-size: ${px(12)};
  font-weight: 500;
  font-family: inherit;
  line-height: 1;
  margin-bottom: ${px(8)};
  opacity: ${p => p.theme.disabledOpacity};

  ::after {
    width: ${px(26)};
    height: ${px(2)};

    content: " ";
    position: absolute;
    border-radius: 1px;
    top: 50%;
    left: -2.75%;
    background-color: ${p => p.theme.detailColor};
    transform: rotateZ(9deg);
  }
`;

const StopName = styled.div`
  padding-left: ${px(12)};
`;
