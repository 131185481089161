import styled from "@style/theme";
import { px } from "@style/scaling";

export type SizeVariant = "bigger" | "smaller";

interface WithSizeVariant {
  size?: SizeVariant;
}

const FACTOR_BIGGER = 1.2;
const FACTOR_SMALLER = 0.8;

const getSizeFactor = (variant?: SizeVariant) =>
  variant === "bigger" ? FACTOR_BIGGER : variant === "smaller" ? FACTOR_SMALLER : 1;

const scaleSize = (size: number, variant?: SizeVariant) => size * getSizeFactor(variant);

/** Turns the text within this span huge, similar to `<Headline>`. */
export const Huge = styled.span<WithSizeVariant>`
  font: bold ${p => px(scaleSize(22, p.size))} ${p => p.theme.headingFontFamily};
  line-height: 1.4;
`;

/**
 * To be used above headlines and similar.
 *
 * SVGs placed inside this element will have an appropriate style applied.
 */
export const OverHeading = styled.h5<WithSizeVariant>`
  font: 500 ${p => px(scaleSize(14, p.size))} ${p => p.theme.headingFontFamily};
  text-transform: uppercase;
  line-height: 1.4;
  opacity: ${p => p.theme.disabledOpacity};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  svg {
    width: ${px(18)};
    height: ${px(18)};
    margin-top: ${px(2)};
    opacity: ${p => p.theme.disabledOpacity};

    &:first-of-type {
      margin-right: 4px;
    }

    &:last-child:not(:first-child) {
      margin-left: 4px;
    }
  }
`;

/**
 * Is similar to `<Huge>` but is also a block element.
 *
 * SVGs placed inside this element will have an appropriate style applied.
 */
export const Headline = styled.h1<WithSizeVariant>`
  font: bold ${p => px(scaleSize(22, p.size))} ${p => p.theme.headingFontFamily};
  line-height: 1.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  svg {
    width: ${px(24)};
    height: ${px(24)};
    fill: ${p => p.theme.detailColor};

    &:first-of-type {
      margin-right: 6px;
    }

    &:last-child:not(:first-child) {
      margin-left: 6px;
    }
  }
`;

/**
 * For use under headlines and similar things.
 *
 * SVGs placed inside this element will have an appropriate style applied.
 */
export const SubHeading = styled.h4<WithSizeVariant>`
  font: 400 ${p => px(scaleSize(16, p.size))} ${p => p.theme.contentFontFamily};
  line-height: 1.4;
  opacity: ${p => p.theme.disabledOpacity};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  svg {
    width: ${px(18)};
    height: ${px(18)};
    margin-top: ${px(2)};
    opacity: ${p => p.theme.disabledOpacity};

    &:first-of-type {
      margin-right: 6px;
    }

    &:last-child:not(:first-child) {
      margin-left: 6px;
    }
  }
`;
