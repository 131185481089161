import React, { CSSProperties, PropsWithChildren, useEffect, useState } from "react";
import { SwipeCallback, useSwipeable } from "react-swipeable";

import { clamp } from "@util/math";
import { noop } from "@util/noop";

import styled from "@style/theme";

import { clampSwipeVelocity, SwipePhase, getEasingAtPhase } from "./swipe";
import { FloatingPane } from "../panel";

export type SidebarOrigin = "left" | "right";

export interface SidebarProps {
  /** Whether the sidebar swipes out from the right, or left. */
  origin?: "left" | "right";
  /** The width of the sidebar when it's open */
  width?: number;
  /** The color of the tint (or overlay) behind the sidebar */
  tintColor?: string;
  /** The opacity of the tint (or overlay) behind the sidebar when it's fully open. */
  tintOpacity?: number;
  /**
   * At which percentage the sidebar should automatically open or close.
   *
   * **Example:** if this factor is set to `0.25`,
   * the sidebar will automatically open or close
   * after it has been swiped 25% of its width.
   */
  breakFactor?: number;
  /**
   * How many pixels the sidebar has to be swiped
   * horizontally before it will start to move.
   *
   * This is useful when there is scrollable content
   * inside the sidebar.
   */
  deadzone?: number;
}

export interface SidebarDispatch {
  /** Called after the sidebar has closed. */
  onClosed?(): void;
}

type AllSidebarProps = PropsWithChildren<SidebarProps & SidebarDispatch>;

/** The duration it should take to animate the sidebar open or closed */
export const ANIM_DURATION = 400;

/**
 * A swipeable sidebar component.
 *
 * **NOTE:** As soon as the sidebar is mounted, it will animate open.
 */
export function Sidebar({
  origin = "left",
  width = 300,
  breakFactor = 0.25,
  deadzone = 24,
  onClosed = noop,
  tintColor = "#000",
  tintOpacity = 0.6,
  children
}: AllSidebarProps): JSX.Element | null {
  const closedOffset = origin === "left" ? -width : width;

  const [phase, setPhase] = useState(SwipePhase.OPENING);
  const [offset, setOffset] = useState(closedOffset);

  const animateOpen = () => {
    setPhase(SwipePhase.OPENING);
    setOffset(0);
  };

  const animateClose = () => {
    setPhase(SwipePhase.CLOSING);
    setOffset(closedOffset);
  };

  /** Called "while" the sidebar is being swiped */
  const onSwiping: SwipeCallback = ({ deltaX, velocity }) => {
    if (Math.abs(deltaX) < deadzone) {
      return;
    }

    const swipeOffset = -(deltaX * clampSwipeVelocity(velocity));
    const clampedOffset =
      origin === "left" ? clamp(swipeOffset, -width, 0) : clamp(swipeOffset, 0, width);

    setPhase(SwipePhase.BEING_SWIPED);
    setOffset(clampedOffset);
  };

  /** Called once the user is done swiping */
  const onSwiped: SwipeCallback = () => {
    const breakpoint = width * breakFactor;

    if (Math.abs(offset) > breakpoint) {
      animateClose();
    } else {
      animateOpen();
    }
  };

  const onPhaseChanged = () => {
    if (phase === SwipePhase.CLOSED) {
      onClosed();
    } else if (phase === SwipePhase.CLOSING) {
      setTimeout(() => setPhase(SwipePhase.CLOSED), ANIM_DURATION);
    } else if (phase === SwipePhase.OPENING) {
      setTimeout(() => setPhase(SwipePhase.OPEN), ANIM_DURATION);
    }
  };

  // Runs when the component is mounted
  useEffect(animateOpen, []);

  // Called each time the phase changes
  useEffect(onPhaseChanged, [phase]);

  const tintStyle: CSSProperties = {
    opacity: (tintOpacity * (width - Math.abs(offset))) / width,
    backgroundColor: tintColor,
    transitionDuration: phase !== SwipePhase.BEING_SWIPED ? `${ANIM_DURATION}ms` : "0ms"
  };

  const style: CSSProperties = {
    width,
    left: origin === "left" ? "0" : "initial",
    right: origin === "right" ? "0" : "initial",
    transform: `translateX(${offset}px)`,
    transitionDuration: phase === SwipePhase.BEING_SWIPED ? "0ms" : `${ANIM_DURATION}ms`,
    transitionTimingFunction: getEasingAtPhase(phase)
  };

  const handler = useSwipeable({ onSwiping, onSwiped });

  if (phase === SwipePhase.CLOSED) {
    return null;
  }

  return (
    <Wrapper {...handler}>
      <Tint style={tintStyle} onClick={animateClose} />
      <Content style={style}>{children}</Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 3;
`;

const Tint = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: transparent;
  will-change: opacity, transition-duration;
  transition: opacity 0ms linear;
`;

const Content = styled(FloatingPane)`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  bottom: 0;
  top: 0;
  height: 100%;
  will-change: transform, transition-duration, transition-timing-function;
  transition: transform ${ANIM_DURATION}ms ${getEasingAtPhase(SwipePhase.CLOSED)};

  > * {
    flex: 1;
  }
`;
