import React from "react";

export const plusIcon = (
  <svg viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
    <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
  </svg>
);

export const minusIcon = (
  <svg viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
    <path d="M0 9h24v6h-24z" />
  </svg>
);
